import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CartItem,
  cartState,
  cityDataState,
  cityIdState,
  reservationsState,
} from "../../App";
import { staticFiles } from "../../shared";
import { SpaceY } from "../../shared/components/Utils";
import Checkout from "./Checkout";

type cartData = {
  adultInfo: CartItem[];
  childInfo: CartItem[];
};

const stripeTestPromise = loadStripe(
  `pk_test_51NXXnoFiJUoJo4YUdGLKaPwGOD0GU5bvut6NJPONPhcVMaieF2q1LvRM6JmvbGwuUJunuNUmTjgG1cdUrwb2DOOR00lOkeErhO`
);

const getSubTotal = (
  ticket: CartItem,
  map: Map<any, any>,
  individualTicketsMap: any[]
) => {
  let totalPrice = 0;
  let key = ticket?.cartId;
  let subCategoryId = ticket?.subCategoryId;
  if (key && subCategoryId) {
    let existingTickets = map.get(key) || [];
    if (existingTickets.length === 0) {
      totalPrice +=
        Number(ticket?.subtotal) == -100
          ? -(Number(ticket?.subCategoryPrice) + Number(ticket?.addition)) *
            Number(ticket?.quantity)
          : (Number(ticket?.subCategoryPrice) + Number(ticket?.addition)) *
            Number(ticket?.quantity);
    } else {
      totalPrice +=
        Number(ticket?.subtotal) == -100
          ? -Number(ticket?.addition) * Number(ticket?.quantity)
          : Number(ticket?.addition) * Number(ticket?.quantity);
    }
    map.set(key, existingTickets.concat(ticket));
  } else {
    individualTicketsMap.push(ticket);
    totalPrice +=
      Number(ticket?.subtotal) == -100
        ? -(Number(ticket?.price) + Number(ticket?.addition)) *
          Number(ticket?.quantity)
        : (Number(ticket?.price) + Number(ticket?.addition)) *
          Number(ticket?.quantity);
  }

  return totalPrice;
};

// Variable to store subpath abbreviation
const subPathWithAbbreviation = [
  { abb: "뉴욕", name: "뉴욕" },
  { abb: "샌프란", name: "샌프란시스코" },
  { abb: "LA", name: "Los Angeles" },
];

const parseCartData = (data: cartData) => {
  const adultSubCategoriesMap = new Map();
  const childSubCategoriesMap = new Map();
  const adultIndividualTicketsMap: any[] = [];
  const childIndividualTicketsMap: any[] = [];
  let totalPrice = 0;

  data?.adultInfo?.forEach((ticket: CartItem) => {
    totalPrice += getSubTotal(
      ticket,
      adultSubCategoriesMap,
      adultIndividualTicketsMap
    );
  });

  data?.childInfo?.forEach((ticket: CartItem) => {
    totalPrice += getSubTotal(
      ticket,
      childSubCategoriesMap,
      childIndividualTicketsMap
    );
  });

  return {
    adultSubCategoriesMap: Array.from(adultSubCategoriesMap),
    childSubCategoriesMap: Array.from(childSubCategoriesMap),
    adultIndividualTicketsMap,
    childIndividualTicketsMap,
    totalPrice,
  };
};

const TicketCartView = ({
  tickets,
  adult_child_type,
  handleDeleteItem,
  navigate,
  handleEdit,
  isBookingEdit,
}: any) => {
  const [cityId, setCityId] = cityIdState.useState();
  const [cityData, setCityData] = cityDataState.useState();

  return tickets?.map((ticket: any, i: number) => {
    let item = {
      reservation_id: ticket.reservation_id,
      item_id: ticket.item_id,
      subcategory_id: ticket.subCategoryId,
      ticket_id: ticket.ticket_id,
      quantity: ticket.quantity,
      price: ticket.price,
      adult_child_type: ticket.adult_child_type,
      subtotal: ticket.subtotal,
    };

    let formattedDatetimeString = "";

    if (ticket?.ticket_type == "Musicals & Shows") {
      const datetimeString = ticket?.tour_date;
      const date = new Date(datetimeString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      let hours = String(date.getHours() % 12 || 12).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const meridiem = date.getHours() >= 12 ? "PM" : "AM";
      formattedDatetimeString = `${year}-${month}-${day} ${hours}:${minutes} ${meridiem}`;
    }

    return (
      <div key={i}>
        <div className="font-poppins text-darkGray">
          <div className="flex grid items-center justify-center grid-cols-6 p-1 mb-4 text-xs text-center border-[1px] border-solid md:grid-cols-12 sm:text-sm border-black bg-[rgb(189,215,238)]">
            <div className="col-span-1 text-left md:col-span-2">
              <button
                className="text-xs text-left md:underline text-sky-700 sm:text-sm cursor-pointer"
                onClick={() => {
                  let cityName = cityData.cityData.find(
                    (it: any) => it.id == Number(ticket?.cityId)
                  )?.name;
                  localStorage.setItem("cityId", String(cityId));
                  localStorage.setItem("cityName", String(cityName));
                  setCityId(Number(ticket?.cityId));

                  if (
                    ticket?.subCategoryName === "explore-pass" ||
                    ticket?.subCategoryName === "city-pass"
                  ) {
                    navigate(`/package-tour/${ticket?.subCategoryName}`);
                  } else {
                    navigate(`/product-detail/${ticket?.ticket_id}`);
                  }
                }}
              >
                {ticket?.name || "Ticket Name Not Defined"}
              </button>
              {ticket?.ticket_type == "Musicals & Shows" && (
                <div className="text-xs text-black">{ticket?.location}</div>
              )}
            </div>
            <div>
              {ticket?.ticket_type === "SIM card" ||
              ticket?.ticket_type == "Musicals & Shows"
                ? ""
                : adult_child_type}
            </div>
            <div className="hidden col-span-2 md:block">
              {/* if the card type is simcard or hardcopy */}
              {ticket?.tour_date
                ? ticket?.ticket_type == "Musicals & Shows"
                  ? formattedDatetimeString
                  : ticket?.tour_date
                : ticket?.ticket_type === "Hard copy" ||
                  ticket?.ticket_type === "SIM card"
                ? ""
                : "대기 중"}
            </div>
            <div className="hidden md:block">
              ${Number(ticket?.price).toFixed(2)}
            </div>
            <div>{Number(ticket?.quantity)}</div>
            <div className="hidden md:block">${Number(ticket?.addition)}</div>
            <div>
              $
              {(
                (Number(ticket?.price) + Number(ticket?.addition)) *
                Number(ticket?.quantity)
              ).toFixed(2)}
            </div>
            <div className="hidden md:flex items-center justify-center">
              {ticket.reservation_id != null && Number(ticket.subtotal) != -100
                ? "변경"
                : ""}
            </div>
            <div className="w-[16px] lg:w-[30%] xl:w-[20%] hidden">
              {" "}
              {ticket.reservation_id != null &&
              Number(ticket.subtotal) != -100 ? (
                <img
                  width={500}
                  src={staticFiles.images.restart_icon}
                  className="relative object-contain ml-5"
                />
              ) : (
                ""
              )}
            </div>
            {ticket.reservation_id != null &&
              Number(ticket.subtotal) != -100 && (
                <button
                  className="underline text-sky-700 cursor-pointer"
                  onClick={() => {
                    handleEdit();

                    let cityName = cityData.cityData.find(
                      (it: any) => it.id == Number(ticket?.cityId)
                    )?.name;
                    localStorage.setItem("cityId", String(cityId));
                    localStorage.setItem("cityName", String(cityName));
                    setCityId(Number(ticket?.cityId));

                    if (isBookingEdit) {
                      localStorage.setItem("Edit_Item", JSON.stringify(item));
                      navigate(
                        `/product-detail/${
                          ticket?.ticket_id
                        }?edit=${true}&cartId=${
                          ticket.cartId
                        }&type=cart+booking`,
                        { state: item }
                      );
                    } else {
                      navigate(
                        `/product-detail/${
                          ticket?.ticket_id
                        }?edit=${true}&cartId=${ticket.cartId}&type=cart`
                      );
                    }
                  }}
                >
                  수정하기
                </button>
              )}
            {ticket.reservation_id == null && (
              <button
                className="flex justify-end underline text-sky-700 md:justify-center md:flex-none cursor-pointer"
                onClick={() => {
                  handleEdit();

                  let cityName = cityData.cityData.find(
                    (it: any) => it.id == Number(ticket?.cityId)
                  )?.name;
                  localStorage.setItem("cityId", String(cityId));
                  localStorage.setItem("cityName", String(cityName));
                  setCityId(Number(ticket?.cityId));

                  if (isBookingEdit) {
                    localStorage.setItem("Edit_Item", JSON.stringify(item));
                    navigate(
                      `/product-detail/${
                        ticket?.ticket_id
                      }?edit=${true}&cartId=${ticket.cartId}&type=cart+booking`,
                      { state: item }
                    );
                  } else {
                    if (
                      ticket?.subCategoryName === "explore-pass" ||
                      ticket?.subCategoryName === "city-pass"
                    ) {
                      navigate(
                        `/package-tour/${
                          ticket?.subCategoryName
                        }?edit=${true}&cartId=${ticket.cartId}&type=cart`
                      );
                    } else if (ticket?.ticket_type == "Musicals & Shows") {
                      navigate(
                        `/musicals-and-shows/${
                          ticket?.tour_date.split(",")[0]
                        }, ${
                          ticket?.tour_date.split(",")[1]
                        }/${ticket?.tour_date.split(",")[2].trim()}/${
                          ticket?.ticket_id
                        }/${ticket?.name}?edit=${true}&cartId=${
                          ticket.cartId
                        }&type=cart&music_id=${ticket?.music_id}&quantity=${
                          ticket?.quantity
                        }`
                      );
                    } else {
                      navigate(
                        `/product-detail/${
                          ticket?.ticket_id
                        }?edit=${true}&cartId=${ticket.cartId}&type=cart`
                      );
                    }
                  }
                }}
              >
                수정하기
              </button>
            )}
            {ticket.reservation_id != null &&
              Number(ticket.subtotal) != -100 && (
                <button className="flex items-center justify-center justify-end text-orange-700 underline md:justify-center md:flex-none ">
                  <div
                    className="w-[14px] lg:w-16px"
                    onClick={() =>
                      handleDeleteItem(ticket?.cartId, adult_child_type)
                    }
                  >
                    <img
                      width={500}
                      src={staticFiles.images.delete_icon}
                      className="relative object-contain"
                    />
                  </div>
                </button>
              )}
            {ticket.reservation_id == null && (
              <button className="flex items-center justify-end text-orange-700 underline md:justify-center md:flex-none">
                <div
                  className="w-[14px] lg:w-16px"
                  onClick={() =>
                    handleDeleteItem(ticket?.cartId, adult_child_type)
                  }
                >
                  <img
                    width={500}
                    src={staticFiles.images.delete_icon}
                    className="relative object-contain"
                  />
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  });
};

const SubCategoryCartView = ({
  subCategories,
  adult_child_type,
  handleDeleteItem,
  navigate,
  handleEdit,
  isBookingEdit,
}: any) => {
  const [cityId, setCityId] = cityIdState.useState();
  const [cityData, setCityData] = cityDataState.useState();
  return subCategories?.map((subCategory: any, i: number) => {
    const subCategorySummary = {
      title:
        subCategory[1][0]?.priceOptionTitle ||
        subCategory[1][0]?.subCategoryTitle,
      price: Number(subCategory[1][0]?.subCategoryPrice),
      quantity: Number(subCategory[1][0]?.quantity),
      addition: 0,
      subTotal: 0,
      subPath: "",
      cartId: "",
      type: "",
      cityId: subCategory[1][0]?.cityId,
    };
    subCategory[1]?.forEach((ticket: any) => {
      subCategorySummary.addition +=
        Number(ticket?.addition) * Number(ticket?.quantity);
    });
    subCategorySummary.subTotal =
      subCategorySummary.price * subCategorySummary.quantity +
      subCategorySummary.addition;

    subCategorySummary.subPath = subCategory[1][0].subPath;
    subCategorySummary.cartId = subCategory[1][0].cartId;
    subCategorySummary.type =
      subCategory[1][0].reservation_id != null &&
      Number(subCategory[1][0].subtotal) != -100
        ? "변경"
        : "";

    let item = {
      reservation_id: subCategory[1][0].reservation_id,
      item_id: subCategory[1][0].item_id,
      subcategory_id: subCategory[1][0].subCategoryId,
      tickets: subCategory[1].map((it: any) => {
        return {
          ticket_id: it.ticket_id.split("+")[0],
          subitem_id: it.ticket_id.split("+")[1],
          ticket_type: it.ticket_type,
          ticket_sent_status: it.ticket_sent_status,
        };
      }),
      quantity: subCategory[1][0].quantity,
      price: subCategory[1][0].price,
      adult_child_type: subCategory[1][0].adult_child_type,
      subtotal: subCategory[1][0].subtotal,
    };
    function navigateToProductDetail(ticket_id: string) {
      setCityId(subCategorySummary.cityId);
      navigate(`/product-detail/${ticket_id}`);
    }
    return (
      <div className="" key={i}>
        <div className="text-xs text-center sm:text-sm font-poppins text-darkGray">
          <div className="grid grid-cols-6 p-1 border-[1px] border-solid md:grid-cols-12 border-black bg-[rgb(189,215,238)]">
            <div className="col-span-1 text-left md:col-span-2 text-blue">
              {subCategorySummary?.title}
            </div>
            <div>{item.adult_child_type || adult_child_type}</div>
            <div className="hidden col-span-3 md:col-span-2 md:block"></div>
            <div className="hidden md:block">${subCategorySummary?.price}</div>
            <div>{subCategorySummary?.quantity}</div>
            <div className="hidden md:block">
              ${subCategorySummary?.addition.toFixed(2)}
            </div>
            <div>${subCategorySummary?.subTotal.toFixed(2)}</div>
            <div className="hidden md:flex items-center justify-center">
              {subCategorySummary?.type}
            </div>
            <div className="w-[16px] block md:hidden flex items-center justify-center hidden">
              {" "}
              {subCategory[1][0].reservation_id !== null &&
              Number(subCategory[1][0].subtotal) !== -100 ? (
                <img
                  alt=""
                  width={500}
                  src={staticFiles.images.restart_icon}
                  className="relative object-contain ml-5"
                />
              ) : (
                ""
              )}
            </div>
            {subCategory[1][0].reservation_id !== null &&
              Number(subCategory[1][0].subtotal) !== -100 && (
                <button
                  onClick={() => {
                    handleEdit();

                    // let cityName = subPathWithAbbreviation.find(
                    //   (it: any) =>
                    //     it.abb == subCategorySummary?.title.split(" ")[0] ||
                    //     it.abb == subCategorySummary?.title?.substring(0, 2)
                    // )?.name;

                    // let cityId = cityData.cityData.find((it: any) => it.name == cityName)?.id;
                    // setCityId(cityId);
                    // localStorage.setItem("cityId", String(cityId));
                    // localStorage.setItem("cityName", String(cityName));

                    if (isBookingEdit) {
                      localStorage.setItem("Edit_Item", JSON.stringify(item));
                      navigate(
                        `/package-tour/${
                          subCategorySummary.subPath
                        }?edit=${true}&cartId=${
                          subCategorySummary.cartId
                        }&type=cart+booking`,
                        { state: item }
                      );
                    } else {
                      navigate(
                        `/package-tour/${
                          subCategorySummary.subPath
                        }?edit=${true}&cartId=${
                          subCategorySummary.cartId
                        }&type=cart`
                      );
                    }
                  }}
                  className="flex underline text-sky-700 cursor-pointer"
                >
                  수정하기
                </button>
              )}
            {subCategory[1][0].reservation_id == null && (
              <button
                onClick={() => {
                  handleEdit();
                  setCityId(subCategorySummary.cityId);
                  if (isBookingEdit) {
                    localStorage.setItem("Edit_Item", JSON.stringify(item));
                    navigate(
                      `/package-tour/${
                        subCategorySummary.subPath
                      }?edit=${true}&cartId=${
                        subCategorySummary.cartId
                      }&type=cart+booking`,
                      { state: item }
                    );
                  } else {
                    navigate(
                      `/package-tour/${
                        subCategorySummary.subPath
                      }?edit=${true}&cartId=${
                        subCategorySummary.cartId
                      }&type=cart`
                    );
                  }
                }}
                className="flex justify-end underline text-sky-700 md:justify-center md:flex-none cursor-pointer"
              >
                수정하기
              </button>
            )}
            {subCategory[1][0].reservation_id != null &&
              Number(subCategory[1][0].subtotal) != -100 && (
                <button className="flex items-center justify-end text-orange-700 underline md:justify-center md:flex-none">
                  <div
                    className="w-[14px] lg:w-16px"
                    onClick={() =>
                      handleDeleteItem(subCategory[0], adult_child_type)
                    }
                  >
                    <img
                      width={700}
                      src={staticFiles.images.delete_icon}
                      className="relative object-contain"
                    />
                  </div>
                </button>
              )}
            {subCategory[1][0].reservation_id == null && (
              <button className="flex items-center justify-end text-orange-700 underline md:justify-center md:flex-none">
                <div
                  className="w-[14px] lg:w-16px"
                  onClick={() =>
                    handleDeleteItem(subCategory[0], adult_child_type)
                  }
                >
                  <img
                    width={700}
                    src={staticFiles.images.delete_icon}
                    className="relative object-contain"
                  />
                </div>
              </button>
            )}
          </div>
        </div>
        {/* <SpaceY /> */}
        {subCategory[1]?.map((ticket: any, ti: number) => {
          return (
            <div className="font-poppins text-darkGray" key={ti}>
              <div
                className={`flex grid items-center justify-center grid-cols-6 p-1 text-sm text-center md:grid-cols-12 ${
                  ti + 1 != subCategory[1]?.length ? "border-b-[1px]" : ""
                } border-[rgb(172,172,172)]`}
              >
                <div
                  className="col-span-3 text-xs text-left md:col-span-3 md:underline text-sky-700 sm:text-sm cursor-pointer"
                  onClick={navigateToProductDetail.bind(
                    this,
                    ticket?.ticket_id
                  )}
                >
                  {ticket?.name || "Ticket Name Not Defined"}
                  <div className="col-span-3 md:col-span-2 block md:hidden text-[#747474]">
                    <div className="flex flex-wrap">
                      <span className="mr-2 font-medium md:hidden">
                        Scheduled Date
                      </span>{" "}
                      {ticket?.tour_date
                        ? ticket?.tour_date
                        : ticket?.ticket_type === "Hard copy" ||
                          ticket?.ticket_type === "SIM card"
                        ? ""
                        : "대기 중"}
                    </div>
                  </div>
                </div>
                {/* <div className="hidden md:block"></div> */}
                <div className="hidden"></div>
                <div className="hidden"></div>
                <div className="hidden col-span-2 md:block">
                  {/* if the card type is simcard or hardcopy */}
                  {ticket?.tour_date
                    ? ticket?.tour_date
                    : ticket?.ticket_type === "Hard copy" ||
                      ticket?.ticket_type === "SIM card"
                    ? ""
                    : "대기 중"}
                </div>
                <div className="hidden md:block"></div>
                <div className="hidden md:block"></div>
                <div className="self-start text-xs sm:text-sm md:self-auto">
                  ${Number(ticket?.addition)}
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
          );
        })}
      </div>
    );
  });
};

const authToken = localStorage.getItem("authToken");
const isMemberLoggedIn = localStorage.getItem("loginData") === "email";

export const CartView = () => {
  // Global State Variable
  const [reservationsData, setReservationsData] = reservationsState.useState();

  // Component State Variable
  const [cartRawData, setCartRawData] = useState(null as cartData | null);
  const [cartRefinedData, setCartRefinedData] = useState(null as any);
  const [cart, setCart] = cartState.useState();
  const [shouldBlock, setShouldBlock] = useState(false);
  const [autoLogout, setAutoLogout] = useState(false);
  const [edit, setEdit] = useState(false);
  const [buyResponse, setBuyResponse] = useState<any>();

  // Create a navigate instance from useNavigate Hook
  const navigate = useNavigate();

  const handleBeforeUnload = (event: any) => {
    event.preventDefault();
    event.returnValue = ""; // Legacy method for some browsers
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Function to check auth token expiration
  const checkTokenExpiration = () => {
    const token = localStorage.getItem("authToken");

    if (token) {
      const decodedToken: any = jwtDecode(token);

      const currentTime = Date.now() / 1000; // Convert current time to seconds

      if (decodedToken.exp < currentTime) {
        // Token has expired, force logout here
        // For example, clear the token from local storage and redirect the user to the login page
        localStorage.removeItem("authToken");
        localStorage.removeItem("loginData");
        localStorage.removeItem("order_number");
        localStorage.removeItem("useremail");

        setAutoLogout(true);

        navigate("/");
      }
    }
  };

  // useEffect Hooks
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    const intervalId = setInterval(checkTokenExpiration, 1000); // Check token expiration every second
    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);

  useEffect(() => {
    const rawData = localStorage.getItem("CART_DATA");
    if (rawData) {
      const parsedData = JSON.parse(rawData);
      setCartRawData(parsedData);
      setCartRefinedData(parseCartData(parsedData));
    }
  }, []);

  useEffect(() => {
    setShouldBlock(checkPendingBookingEditInShoppingCart(cart));
  }, [cart]);

  useEffect(() => {
    return () => {
      // Remove Booking Edit items in Cart
      if (shouldBlock && !autoLogout && !edit) {
        // alert(edit)
        // alert("Delete localstorage");
        // let cartData = localStorage.getItem("CART_DATA")
        //   ? JSON.parse(localStorage.getItem("CART_DATA") || "")
        //   : { adultInfo: [], childInfo: [] };
        // cartData.adultInfo = cartData.adultInfo.filter(
        //   (item: any) => item.reservation_id == null
        // );
        // cartData.childInfo = cartData.childInfo.filter(
        //   (item: any) => item.reservation_id == null
        // );
        // localStorage.setItem("CART_DATA", JSON.stringify(cartData));
        // setCart(cartData);
      }
    };
  }, [shouldBlock]);

  // Function to set Edit state
  const handleSetEdit = () => {
    setEdit(true);
  };

  // Function to handle deleting item
  const handleDeleteItem = (
    cartId: string,
    adult_child_type: "성인" | "아동"
  ) => {
    let cartData = localStorage.getItem("CART_DATA")
      ? JSON.parse(localStorage.getItem("CART_DATA") || "")
      : { adultInfo: [], childInfo: [] };
    let cartItem = [...cartData.adultInfo, ...cartData.childInfo].filter(
      (item: any) => item.cartId == cartId
    );

    if (adult_child_type === "성인") {
      cartData.adultInfo = cartData.adultInfo.filter(
        (item: any) => item.cartId !== cartId
      );
    } else if (adult_child_type === "아동") {
      cartData.childInfo = cartData.childInfo.filter(
        (item: any) => item.cartId !== cartId
      );
    }

    // remove related ones when deleting booking Edit items
    let reservation_id =
      cartItem.length > 0 ? cartItem[0]?.reservation_id : null;

    if (reservation_id) {
      cartData.adultInfo = cartData.adultInfo.filter(
        (item: any) => item.reservation_id !== reservation_id
      );
      cartData.childInfo = cartData.childInfo.filter(
        (item: any) => item.reservation_id !== reservation_id
      );
    }

    if (cartData?.selectInfo) {
      cartData.selectInfo = cartData.selectInfo.filter(
        (item: any) => item.cartId !== cartId
      );
    }

    localStorage.setItem("CART_DATA", JSON.stringify(cartData));
    setCartRawData(cartData);
    setCartRefinedData(parseCartData(cartData));
    setCart(cartData);
    toast("삭제 하였습니다!");
  };

  // Function to check pending booking edit item
  const checkPendingBookingEditInShoppingCart = ({
    childInfo,
    adultInfo,
  }: {
    childInfo: CartItem[];
    adultInfo: CartItem[];
  }) => {
    let result = false;
    const combinedInfo = [...childInfo, ...adultInfo];

    for (let info of combinedInfo) {
      if (info.reservation_id) {
        result = true;
        break;
      }
    }

    return result;
  };

  /**
   * handleBuyOnInbound
   *
   * this function should be called once payment has been processed
   * it is meant to process info that was on the cart
   */
  // const handleBuyOnInbound = async () => {
  //   try {
  //     const selectInfo = cart.selectInfo[0];
  //     const response = await axios.post(`${API}/buy-seat`, {
  //       "product_id": selectInfo.product_id,
  //       "show_code": selectInfo.show_code,
  //       "quantity": selectInfo.quantity,
  //       "event_date_time": selectInfo.event_date_time,
  //       "price": selectInfo.price,
  //       "booking_last_name": "Wambua",
  //       "booking_first_name": "Jonathan",
  //       "booking_email_address": "jonathanwambua10219@gmail.com",
  //       "area": selectInfo.seating_info.Seat.Area,
  //       "low_seat_num": selectInfo.seating_info.Seat.LowSeatNumber,
  //       "high_seat_num": selectInfo.seating_info.Seat.HighSeatNumber,
  //       "row": selectInfo.seating_info.Seat.Row,
  //       "section": selectInfo.seating_info.Seat.Section,
  //       "session_id": selectInfo.session
  //     });
  //     setBuyResponse(response.data);
  //     return response.data;
  //   } catch (error) {
  //     console.log('Error buying ticket', error);
  //   }
  // }

  return (
    <Elements stripe={stripeTestPromise}>
      <main className="flex flex-col items-center w-full">
        <div className=" w-full md:max-w-[1600px] min-h-[600px] pb-[10vh] pt-[10vh] flex justify-center px-4">
          <div className="flex flex-col w-full gap-x-4 xl:flex-row">
            <div className="flex flex-col w-full p-4 bg-white">
              <div className="w-full md:justify-between">
                <span className="font-normal font-poppins text-darkGray">
                  장바구니
                </span>
              </div>
              <SpaceY />
              <hr className="hidden w-full border rounded md:block border-gray" />
              <div className="hidden font-poppins text-darkGray md:block">
                <div className="grid grid-cols-12 p-1 text-sm font-normal text-center">
                  <div className="flex items-center justify-center col-span-2 lg:block vertical-lr">
                    상품
                  </div>
                  <div className="flex items-center justify-center col-span-1 lg:block vertical-lr">
                    성인/아동
                  </div>
                  <div className="flex items-center justify-center col-span-2 lg:block vertical-lr">
                    예약날짜/시간
                  </div>
                  <div className="flex items-center justify-center lg:block vertical-lr">
                    가격
                  </div>
                  <div className="flex items-center justify-center lg:block vertical-lr">
                    수량
                  </div>
                  <div className="flex items-center justify-center lg:block vertical-lr">
                    추가금액
                  </div>
                  <div className="flex items-center justify-center lg:block vertical-lr">
                    소계
                  </div>
                  <div className="flex items-center justify-center lg:block vertical-lr">
                    상품타입
                  </div>
                  <div></div>
                </div>
              </div>
              <hr className="hidden w-full border rounded md:block border-gray" />
              <div>
                <SpaceY />
                <SubCategoryCartView
                  subCategories={cartRefinedData?.adultSubCategoriesMap || []}
                  adult_child_type={"성인"}
                  handleDeleteItem={handleDeleteItem}
                  navigate={navigate}
                  handleEdit={handleSetEdit}
                  isBookingEdit={shouldBlock}
                />
                <SubCategoryCartView
                  subCategories={cartRefinedData?.childSubCategoriesMap || []}
                  adult_child_type={"아동"}
                  handleDeleteItem={handleDeleteItem}
                  navigate={navigate}
                  handleEdit={handleSetEdit}
                  isBookingEdit={shouldBlock}
                />
                {/* <SpaceY />
                <SpaceY /> */}
                <TicketCartView
                  tickets={cartRefinedData?.adultIndividualTicketsMap || []}
                  adult_child_type={"성인"}
                  handleDeleteItem={handleDeleteItem}
                  navigate={navigate}
                  handleEdit={handleSetEdit}
                  isBookingEdit={shouldBlock}
                />
                <TicketCartView
                  tickets={cartRefinedData?.childIndividualTicketsMap || []}
                  adult_child_type={"아동"}
                  handleDeleteItem={handleDeleteItem}
                  navigate={navigate}
                  handleEdit={handleSetEdit}
                  isBookingEdit={shouldBlock}
                />
              </div>
              {/* <hr className="hidden w-full my-5 border rounded md:block border-gray" /> */}
              {/* <SpaceY />
              <SpaceY /> */}
              {/* <div className="grid grid-cols-11 pr-2 text-center text-darkGray">
              <div className="col-start-9">
                Total : ${cartRefinedData?.totalPrice || 0}
              </div>
            </div> */}
              {((cartRawData?.adultInfo && cartRawData.adultInfo.length > 0) ||
                (cartRawData?.childInfo &&
                  cartRawData?.childInfo?.length > 0)) &&
                cartRefinedData?.totalPrice == 0 && (
                  <div>
                    <div className="flex flex-col items-center w-full mt-10 md:flex">
                      <p className="text-blue">
                        You have no balance to pay and the change will be
                        updated in My Page.
                      </p>
                    </div>
                    <div className="flex flex-col items-center w-full mt-10 md:flex">
                      <button
                        className="w-1/4 px-4 py-2 font-medium text-white bg-blue"
                        onClick={() => {
                          if (authToken && isMemberLoggedIn) {
                            navigate("/my-page");
                          } else {
                            navigate("/no-auth-checkout");
                          }
                        }}
                      >
                        마이페이지로 이동하기
                      </button>
                    </div>
                  </div>
                )}
            </div>
            {cartRefinedData?.totalPrice != 0 &&
              cartRefinedData?.totalPrice && (
                <div>
                  <Checkout totalPrice={cartRefinedData?.totalPrice || 0} />
                </div>
              )}
          </div>
        </div>
      </main>
    </Elements>
  );
};

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(  
  <GoogleOAuthProvider clientId="293097530179-m40bn0qrls42mlenncb0rhsnkd476dd3.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider> 
);
